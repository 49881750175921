export const LABEL_CONSTANT = {
  CHANGE: $localize`:@@change:Change`,
  CHANGE_PASSWORD: $localize`:@@changePassword:Change Password`,
  CLICK_TO_SEND_AGAIN: $localize`:@@clickToSendAgain:Click to send again`,
  CODE_SENT: $localize`:@@codeSent:Code sent`,
  CONFIRM_PASSWORD: $localize`:@@confirmPassword:Confirm Password`,
  CRITERIA_ACCEPTED: $localize`:@@criteriaAccepted:criteria accepted`,
  CRITERIA_NOT_ACCEPTED: $localize`:@@criteriaNotAccepted:criteria not accepted`,
  CODE: $localize`:@@code:Code`,
  DESCRIPTION: $localize`:@@description:Description`,
  ENTER: $localize`:@@enter:Enter`,
  ENTER_CODE: $localize`:@@enterCode:Open your authenticator app and enter your given code below:`,
  FORGOT_PASSWORD: $localize`:@@forgotPassword:Forgot Password`,
  LOADING_PLEASE_WAIT: $localize`:@@loadingPleaseWait:Loading, please wait...`,
  LOGIN: $localize`:Log in button@@loginButton:Login`,
  NEW_PASSWORD: $localize`:@@password:New Password`,
  NEXT: $localize`:Next button@@nextButton:Next`,
  ORGANIZATION: $localize`:@@Organization:organization`,
  PASSWORD: $localize`:@@password:Password`,
  PRIVACY_POLICY: $localize`:@@privacyPolicy:Privacy Policy`,
  PASSWORD_REQUIREMENTS: $localize`:@@passwordRequirements:Password Requirements`,
  RETURN_HOME: $localize`:@@returnHome:Return Home`,
  SELECT_ORGANIZATION: $localize`:@@selectOrganization:Select Organization`,
  SEND_CODE: $localize`:@@sendCode:Send Code`,
  SETUP: $localize`:@@setup:Setup`,
  SETUP_MFA: $localize`:@@setupMFA: Set Up Multi-Factor Authentication`,
  SYSTEM_ACCESS_NOTIFICATION: $localize`:@@systemAccessNotification:System Access Notification`,
  TERMS_OF_USE: $localize`:@@termsOfUse:Terms of Use`,
  TWO_FACTOR_AUTHENTICATION: $localize`:@@twoFactorAuthentication:Two-Factor Authentication`,
  UPDATE: $localize`:@@update:Update`,
  USERNAME_EMAIL: $localize`:netsmartID@@usernameEmail:Username/Email`,
  VERIFYING: $localize`:@@verifying:Verifying...`,
};

export const MFA_SETUP_CONSTANT = {
  STEP_ONE: $localize`:@@stepOne:1. Download an Authenticator App`,
  STEP_ONE_DESCRIPTION: $localize`:@@stepOneDescription:Install one of these authenticator apps on your mobile device:`,
  AUTHY:  $localize`:@@authy:Authy`,
  GOOGLE_AUTHENTICATOR:  $localize`:@@googleAuthenticator:Google Authenticator`,
  MICROSOFT_AUTHENTICATOR: $localize`:@@microsoftAuthenticator:Microsoft Authenticator`,
  TO_GET_STARTED: $localize`:@@toGetStarted:To get started:`,
  VISIT_APP_STORE: $localize`:@@visitAppStore:Visit your device's app store (App Store for iOS or Google Play Store for Android)`,
  SEARCH: $localize`:@@search:Search for one of the authenticator apps listed above`,
  DOWNLOAD: $localize`:@@download:Search for one of the authenticator apps listed above`,
  OPEN: $localize`:@@open:Open the app and tap the "+" or "Add Account" button`,
  CHOOSE: $localize`:@@choose:Choose "Scan QR Code" when prompted`,
  NOTE: $localize`:@@note:Note: If you already have an authenticator app installed, you can skip to Step 2.`,
  STEP_TWO: $localize`:@@stepTwo:2. Scan QR Code`,
  STEP_THREE: $localize`:@@stepThree:3. Enter Verification Code`,
  ENTER_CODE: $localize`:@@enterCode:Enter 6-digit code`,
};

export const PASSWORD_REQUIREMENTS = {
  ONE_LOWERCASE: {
    label: $localize`:@@oneLowercase:Have at least one lower case letter (a-z)`,
    rule: 'noLowerCase',
  },
  ONE_UPPERCASE: {
    label: $localize`:@@oneUppercase:Have at least one uppercase letter (A-Z)`,
    rule: 'noUpperCase',
  },
  ONE_NUMBER: {
    label: $localize`:@@oneNumber:Have at least one number (0-9)`,
    rule: 'noNumber',
  },
  MINIMUM_LENGTH: {
    label: $localize`:@@minimumLength:Minimum length of 12 characters`,
    rule: 'notOfMinimumLength',
  },
  ONE_SPECIAL_CHARACTER: {
    label: $localize`:@@oneSpecialCharacter:Must contain a special character from the following set. (^$*.[]{}()?-"!@#%&/\,><':;|_~\`+=)`,
    rule: 'noSpecialCharacter',
  },
};

export const ARIA_CONSTANT = {
  SYSTEM_ACCESS_NOTIFICATION: $localize`:@@systemAccessNotification:system access notification`,
  SYSTEM_ACCESS_INFORMATION: $localize`:@@systemAccessInformation:system access information`,
  TERMS_OF_USE: $localize`:@@termsOfUse:terms of use`,
  RETURN_TO_HOME: $localize`:@@returnHome:return to home`,
  QR_CODE: $localize`:@@qrCode:Scan the QR code with your mobile phone in your chosen authenticator
  app to obtain your verification code`
}

export const PLACEHOLDER_CONSTANT = {
  EMAIL_FORMAT: $localize`:@@emailFormat:user@domain.org`,
  SIX_DIGIT_CODE: $localize`:@@sixDigitCode:000000`
};

export const HTTP_ERROR_CONSTANT = {
  GENERAL_HTTP_ERROR: $localize`:unexpected Error@unexpectedError:An unexpected error occurred`,
  INCORRECT_USER_NAME_OR_PASSWORD: $localize`:Incorrect username or password@@incorrectUsernamepassword:Incorrect username or password.`,
  ORGANIZATION_NOT_FOUND: $localize`:Organization Not Found@@orgNotFound:We couldn't find your Organization.
    Please consult with your CareConnect administrator.`,
  ORGANIZATION_ERROR_PAGE: $localize`:Organization Error page@@orgErrorPage:We could not find your Organization.
    Please check with your CareConnect NX administrator for your expected login URL.`,
  UNAUTHORIZED_HTTP_ERROR: $localize`:unauthorized error@unauthorizedError:Unauthorized request`,
  MFA_FAILED: $localize`:MFA Failed@@mfaFailed:Failed to setup MFA. Please try again.`
};

export const VALIDATION_ERROR_CONSTANT = {
  INVALID_EMAIL_ADDRESS: $localize`:Invalid Email Address@invalidEmailAddress:should be like`,
  IS_REQUIRED: $localize`:Is Required@@isRequired: is required`,
  PASSWORD_MISMATCH: $localize`:Password Mismatch@@passwordMissmatch:password do not match`,
  QR_NOT_SHOWN: $localize`:Qr Code Not Shown@@qrCodeNotShown:QR code could not be displayed`,
};

export const GENERAL_ERROR_CONSTANT = {
  UNEXPECTED_ERROR: $localize`:Unexpected error@@unexpectedError:An unexpected error occurred. Please try again later.`,
};

export const PAGE_TITLE_CONSTANT = {
  FORGOT_PASSWORD: $localize`:Forgot password@@forgotPassword:Forgot Password`,
  LOGIN: $localize`:Page title Login@@loginPageTitle:Login`,
  ORGANIZATION_NOT_FOUND: $localize`:Page title organization name not found@@organizationNameNotFoundPageTitle:Login -
                                              Organization Name Not Found`,
  SELECT_ORGANIZATION: $localize`:Page title select organization@@selectOrgnizationPageTitle:Select Organization`,
};

export const ROUTE_CONSTANT = {
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  LOGIN_ORGANIZATION_NAME: 'login/organization/:orgId',
  ORGANIZATION_NOT_FOUND: 'organization-error',
  SELECT_ORGANIZATION: 'login/organization',
};

export const LOGIN_CONSTANT = {
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP: 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
  CONFIRM_SIGN_IN_WITH_TOTP_CODE: 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
};
export default LABEL_CONSTANT;
